const initialState = {
  cities: [
    "Hamilton",
    "Toronto",
    "Kitchener-Waterloo",
    "Montreal",
    "Vancouver",
    "London",
  ],
  selectedCity: "",
};

export default initialState;
